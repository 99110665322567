<template>
  <v-sheet>
    <v-container class="py-4 py-lg-8">
      <div class="text-center">
        <h2 class="text-h3 text-lg-h2 text-gradient">The Allview Platform</h2>
        <v-responsive max-width="1200" class="mx-auto">
          <div class="text-h6 text-lg-h5 mt-4">The all-in-one platform that connects people and enterprises from the European wood and furniture industry</div>
        </v-responsive>
      </div>
      <v-row class="mt-4">
        <v-col v-for="(item, i) in features" :key="i" cols="12" md="6">
          <div class="d-flex">
            <div class="mr-2">
              <v-sheet color="surface" rounded class="pa-2">
                <v-icon class="text-gradient">{{ item.icon }}</v-icon>
              </v-sheet>
            </div>
            <div>
              <div class="text-h5">{{ item.title }}</div>
              <div class="text-h6 mt-1">{{ item.description }}</div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      features: [{
        icon: 'mdi-school',
        title: 'Search for training courses',
        description: 'Users can look for training courses by applying filters such as keywords, skills, competences, types, topics, languages and Allview partners'
      }, {
        icon: 'mdi-account-hard-hat',
        title: 'Search for job offers',
        description: 'Registered users can search for job offers by filtering keywords, skills, competences, companies, languages, and contract types'
      }, {
        icon: 'mdi-map-marker-path',
        title: 'Learning paths',
        description: 'Individuals can specify their desired job challenges and the platform will show the recommended skills and competences to learn'
      }, {
        icon: 'mdi-database-search',
        title: 'Identify the most demanded skills',
        description: 'Training providers can identify the most demanded skills and competences requested by companies, and thus they can create the appropriate courses'
      }]
    }
  }
}
</script>

<style scoped>
.text-gradient {
  background: linear-gradient(to right, #047ec3 0%, #52ae32 150%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gray {
  color: #222c3a
}
</style>
